@import "colors";
#home {
	min-height: 50vh;
	display: flex;
	align-items: center;

	h1 {
		font-size: 4rem;
		line-height: 3rem;
		text-align: right;
		// margin-right: -5px;
		word-break: break-all;
		margin-bottom: .5rem;
	}

	p {
		word-break: break-all;
		//margin: 0 -5px;
		font-weight: 400;
		font-size: 1.5em;
		line-height: 1.2em;
	}

	@media screen and (min-width: 1200px) {
		p {
			font-size: 2em;
		}
	}
}
