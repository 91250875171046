@import "variables";

* {
	margin: 0;
	padding: 0;
}

html,
body {
	background: $backgroundColor;
	font-family: $fontFamily;
	font-size: $fontSizeInitial;

	@media screen and (min-width: 600px) {
		font-size: 1.2 * $fontSizeInitial;
	}
	@media screen and (min-width: 1500px) {
		font-size: 2 * $fontSizeInitial;
	}
}

/* Überschriften */
h1,
h2 {
	line-height: 0.75em;
	word-break: break-all;
}
h1 {
	font-size: 3rem;
	text-align: right;
	// margin-right: -5px;
	margin-bottom: 0.5em;
}
h2 {
	font-size: 2rem;
	margin-bottom: 0.5em;
	~ h2 {
		margin-top: 1em;
	}
}

/* Text */
p {
	hyphens: auto;
	padding-bottom: 0.5em;
}

blockquote {
	margin: 1em 0.5em;
	border-left: 0.5em solid $darkPurple;
	padding-left: 1em;
	position: relative;
	z-index: 50;
	&:before {
		content: "\201C";
		color: $darkPurple;
		font-size: 4em;
		position: absolute;
		top: -0.25em;
		left: 0.5rem;
		z-index: -10;
	}
	cite {
		font-size: 0.8em;
		font-style: italic;
	}
}

/* Links */
a {
	color: #000000;
	text-decoration: none;

	&:hover {
		font-style: italic;
	}
	&:active,
	&.active {
		font-style: italic;
		font-weight: bold;
	}
}
p a {
	font-weight: bold;
	border-bottom: 2px solid $darkPurple;
	transition: border-bottom 0.3s ease;
	&:hover {
		border-bottom: 2px solid lighten($darkPurple, 30%);
	}
}

/* Bilder */
img {
	max-width: 100%;
	height: auto;
}
/* Inline-Icons */
.icon {
	display: inline-block;
	height: 1em;
	width: 1em;
}

main ul {
	list-style-type: disc;
	margin-left: 1.5em;
	li {
		padding: 0.25em;
	}
}
