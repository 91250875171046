@import "colors";

#mitarbeiten {
	ul {
		margin: 0 -0.2em;
	}
	li {
		display: inline-block;
		border-radius: 0.5em;
		margin: 0.1em;
		word-break: break-all;
		a {
			display: block;
			padding: 0.3em 0.6em;
		}

		&:nth-of-type(3n - 2) {
			background: $darkPurple;
		}
		&:nth-of-type(3n - 1) {
			background: $yellow;
		}
		&:nth-of-type(3n) {
			background: $green;
		}
	}
}
