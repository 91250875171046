/* Layout */

/* Inhalt */
main {
	border: 7px solid $yellow;
	padding: 4rem 0;
	overflow-y: scroll;
	> section {
		max-width: 55ch;
		margin: 0 auto;
	}
	article {
		padding: 1em 0;
		margin-bottom: 1em;
	}
}

/* Navigation */
#navigation {
	border: 7px solid $darkPurple;
	overflow-x: scroll;
	white-space: nowrap;
	padding: 1rem 0;
	li {
		list-style-type: none;
		display: inline-block;
	}
	a {
		margin: 0 1rem;
	}
}

/* Kontakt */
#contact {
	border: 7px solid $green;
	padding: 1rem;
	text-align: center;
	a {
		border-bottom: 2px solid $green;
	}
}

/* Responsive */
@media screen and (min-width: 1200px) {
	html,
	body {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: stretch;
		height: 100%;
	}
	main {
		height: 100vh;
		min-width: 70vw;
		order: 0;
	}
	#navigation {
		order: 2;
		flex-basis: 60%;
		flex-grow: 3;
		min-width: 20vw;
		ul {
			position: absolute;
			bottom: 1em;
		}
		li {
			display: list-item;
		}
	}
	#contact {
		order: 1;
		flex-basis: 6em;
		min-width: 20vw;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-content: center;
	}
}
